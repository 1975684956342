import { Alert, Anchor, Center, Container, Grid, Group } from '@mantine/core';
import RotatingLogoPage from './RotatingLogoPage';

const RootPage = () => {
  return (
    <Container size="xl">

<Alert
        m="xl"
        title="Hinweis: Diese Webpräsenz befindet sich noch im Aufbau."
        color="red"
        style={{textAlign:'center'}}
      />
      <Group justify="center">
        <Grid mt="xl">

          <Grid.Col span={12}>
            <Center>
              <Anchor
                style={{ fontSize: '2.5rem', color: '#FFF' }}
                href="https://layla-food.s3.us-east-2.amazonaws.com/layla-food-burger-special.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Burger Special Menü (gültig nur am Samstag)
              </Anchor>
            </Center>
          </Grid.Col>
        </Grid>
      </Group>
      <Group justify="center" mt="xl">
        <RotatingLogoPage />
      </Group>
      <Grid>
      <Grid.Col span={12}>
            <Center>
              <Anchor
                style={{ fontSize: '2.5rem', color: '#FFF' }}
                href="https://layla-food.s3.us-east-2.amazonaws.com/layla-food-menu.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Menü (Mo-Fr und So)
              </Anchor>
            </Center>
          </Grid.Col>
          <Grid.Col span={12}>
            <Center>
              <Anchor
                style={{ fontSize: '2.5rem', color: '#FFF' }}
                href="https://layla-food.s3.us-east-2.amazonaws.com/layla-food-cafe-spot.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Cafe Spot Menü
              </Anchor>
            </Center>
          </Grid.Col>
          </Grid>
    </Container>
  );
};

export default RootPage;
