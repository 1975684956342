import { MantineProvider } from '@mantine/core';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RootPage from './pages/RootPage';

const App = () => {
  return (
    <MantineProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RootPage />} />
          <Route path="saturday-menu" element={<RootPage />} />
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  );
};

export default App;
