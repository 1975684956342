import { animated, useSpring } from '@react-spring/web';
import { useLocation } from 'react-router-dom';
import laylaFoodLogo from '../assets/logo.png';

const RotatingLogoPage = () => {
  const location = useLocation();

  const springProps = useSpring({
    config: { duration: 5000 },
    from: { transform: 'rotateY(0deg)' },
    to: { transform: 'rotateY(360deg)' },
    loop: true,
  });

  return (
    <>
      {location.pathname !== '/tv/4' && (
        <animated.img
          src={laylaFoodLogo}
          alt="Rotating Image"
          style={{
            ...springProps,
            width: '350px',
            height: '350px',
          }}
        />
      )}
    </>
  );
};

export default RotatingLogoPage;
